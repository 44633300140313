import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router"
import HomeView from "../views/HomeView.vue"
import Layout from "../views/common/Layout.vue"

const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		name: "layout",
		component: Layout,
		redirect: "/home",
		children: [
			{
				path: "home",
				component: HomeView,
			},
			{
				path: "intro",
				component: () => import("../views/Intro.vue")
			},
			{
				path: "address",
				component: () => import("../views/Address.vue")
			},
			{
				path: "feedback",
				component: () => import("../views/Feedback.vue")
			}
		],
	},
]

const router = createRouter({
	history: createWebHashHistory(),
	routes,
})

export default router
