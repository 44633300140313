<template>
	<div class="share-img">
		<div class="white-title">强大的培训实力</div>

		<div class="share-tabs">
			<div
				class="stabs-item"
				:class="{ active: activeItemIndex == index }"
				v-for="(i, index) in dataList"
				:key="i.title"
				@click="activeItemIndex = index"
			>
				{{ i.title }}
			</div>
		</div>

		<div class="share-imgbox">
			<img
				v-for="(i, index) in currentList"
				:src="i"
				:key="index"
			/>
		</div>
	</div>
</template>

<script setup>
	import { reactive, ref, computed } from "vue"
	const dataList = reactive([
		{
			title: "学校风貌",
			imgList: [
				"https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/share/s1.png",
				"https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/share/s2.png",
				"https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/share/s3.png",
				"https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/share/s4.png",
				"https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/share/s5.png",
				"https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/share/s6.png",
			],
		},
		{
			title: "教学剪影",
			imgList: [
				"https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/share2/1.jpg",
				"https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/share2/2.jpg",
				"https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/share2/3.jpg",
				"https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/share2/4.jpg",
				"https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/share2/5.jpg",
				"https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/share2/6.jpg",
			],
		},
		{
			title: "名师大讲堂",
			imgList: [
				"https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/share3/1.jpg",
				"https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/share3/2.jpg",
				"https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/share3/3.jpg",
				"https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/share3/4.jpg",
				"https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/share3/5.jpg",
				"https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/share3/6.jpg",
			],
		},
		// {
		// 	title: "学员拿证",
		// },
	])

	const activeItemIndex = ref(0)

	const currentList = computed(() => {
		return dataList[activeItemIndex.value]?.imgList || []
	})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
	.share-img {
		height: 656px;
		width: 100vw;
		background: url("https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/share-bg.jpg")
			0 0 no-repeat;
		background-size: 100%;
		margin-bottom: 50px;
		margin-top: 50px;
		padding-top: 30px;

		.white-title {
			color: #fff;
			font-size: 36px;
			position: relative;
			text-align: center;
			margin-bottom: 30px;
			&::after {
				content: "";
				display: block;
				position: absolute;
				left: 50%;
				bottom: -10px;
				width: 50px;
				height: 3px;
				background: #fff;
				transform: translateX(-50%);
			}
		}

		.share-tabs {
			display: flex;
			justify-content: space-around;
			width: 1200px;
			margin: 0 auto;
			border-bottom: 1px solid #fff;
			.stabs-item {
				font-size: 21px;
				color: #fff;
				text-align: center;
				width: 200px;
				line-height: 60px;
				cursor: pointer;
				transition: all 0.3s ease;

				&.active {
					font-weight: bold;
					background: rgba(255, 255, 255, 0.4);
				}
			}
		}

		.share-imgbox {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			width: 1200px;
			margin: 0 auto;

			img {
				width: 360px;
				height: 212px;
				object-fit: cover;
				display: block;
				&:nth-child(n + 1):nth-child(-n + 3) {
					margin: 20px 0;
				}
			}
		}
	}
</style>
