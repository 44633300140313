<template>
	<div class="news-list">
		<div class="news-title">
			<div class="title-name">教务信息</div>
		</div>

		<div class="news-content">
			<div
				class="news-item"
				v-for="(i, index) in newsList"
				:key="index"
				@click="handleClick(i)"
			>
				<div class="item-num">
					<span :style="{ color: index <= 2 && '#0083F5' }">0{{ index+1 }}</span>
				</div>
				<div class="item-title">
					{{ i.title }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { getTopicList } from "@/plugins/api"

	export default {
		data() {
			return {
				maxPage: null,
				page: 1,
				count: 10,
				newsList: [],
			}
		},
		created() {
			this.getTopicData()
		},
		methods: {
			handleClick(i) {
				window.open(`https://edu.yjl.cc/#/topic/${i.id}?ft=1`)
			},
			showOther() {
				if (this.page == this.maxPage) {
					this.page = 1
				} else {
					this.page++
				}
				this.getTopicData()
			},
			getTopicData() {
				getTopicList({
					page: this.page,
					page_size: this.count,
					// 新闻资讯
					category: 3,
				}).then(res => {
					// this.maxPage = Math.ceil(res.data.total / this.count)
					this.newsList = res.data.data.splice(0,6)
				})
			},
		},
	}
</script>

<style lang="less">
	.news-list {
		width: 320px;
		height: 463px;
		background: #ffffff;

		.news-title {
			display: flex;
			justify-content: space-between;
			padding: 13px 11px 0 10px;
			.title-name {
				display: flex;
				align-items: center;
				font-size: 26px;
				color: #3a3d4b;

				.title-icon {
					width: 20px;
					height: 18px;
					margin-left: 9px;
				}
			}

			.title-control {
				font-size: 12px;
				font-weight: 400;
				color: #666666;
				cursor: pointer;
				display: flex;
				align-items: center;
			}
		}

		.news-content {
			height: calc(100% - 46px);
			overflow-y: auto;
			padding: 32px 40px 0 0;
			box-sizing: border-box;
			.news-item {
				margin-bottom: 42px;
				display: flex;
				cursor: pointer;
				align-items: center;

				&:last-child {
					margin-bottom: 0;
				}
				.item-num {
					width: 13px;
					text-align: center;
					color: #D6DBE3;
					font-size: 25px;
					font-weight: bold;
					margin-right: 25px;
					.item-icon {
						width: 16px;
					}
				}
				.item-title {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					font-size: 15px;
					font-weight: 400;
					color: #333333;
					flex: 1;
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
</style>
