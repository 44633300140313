import { createStore } from "vuex";

export default createStore({
  state: {
    name: 1
  },
  getters: {
    getName(state) {
      return state.name + 1
    }
  },
  mutations: {},
  actions: {},
  modules: {},
});
