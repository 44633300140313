import { createApp } from "vue"
import App from "./App.vue"
import "normalize.css"
import "./registerServiceWorker"
import router from "./router"
import store from "./store"
import ElementPlus from "element-plus"
import "element-plus/dist/index.css"
import locale from "element-plus/lib/locale/lang/zh-cn"

import axios from "./plugins/axios"

const app = createApp(App)
app.use(ElementPlus, {locale}).use(store).use(router).mount("#app")

app.config.globalProperties.$axios = axios //配置axios的全局引用
