import axios from "./axios"

const host = "https://api.yjl.cc"
const version = "/api/v1"

export const getTopicList = params => axios.get(`${host}${version}/topic/list`, { params })

// 反馈内容 获取手机验证码
export const createSmsVerify = params => axios.post(`${host}/suggest/code`, params)

// 反馈内容提交
export const feedBackSubmit = params => axios.post(`${host}/suggest/add`, params)
