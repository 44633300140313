<template>
	<div class="school-honor">
		<div class="white-title">相信品牌的力量</div>
		<div class="white-desc">
			&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;广东省艺建联职业培训学校是经广州市人力资源和社会保障局批准成立。并经过广东省住房和城乡建设厅核准认定的以建设类培训为主的职业培训学校。学校秉承“追求卓越，诚信敬业，精艺创新，联聚建设”的办学理念在建设培训领域踏实快进。建设行业工人职业技能培训与鉴定在传统培训考核的同时，又在积极发展职业在线教育。
		</div>

		<div class="honor-swiper">
			<el-carousel
				height="211px"
				arrow="always"
				indicator-position="none"
			>
				<el-carousel-item
					v-for="item in dataList"
					:key="item"
				>
					<div class="img-box">
						<div
							class="img-item"
							v-for="(d, index) in item"
							:key="index"
						>
							<img
								class="honor-img"
								:src="d.img"
							/>
						</div>
					</div>
				</el-carousel-item>
			</el-carousel>
		</div>
	</div>
</template>

<script setup>
	import { reactive } from "vue"
	const dataList = reactive([
		[
			{
				img: "https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/honor/1.png",
			},
			{
				img: "https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/honor/2.png",
			},
			{
				img: "https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/honor/3.png",
			},
			{
				img: "https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/honor/4.png",
			},
		],
		[
			{
				img: "https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/honor/5.png",
			},
			{
				img: "https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/honor/6.png",
			},
			{
				img: "https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/honor/7.png",
			},
			{
				img: "https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/honor/8.png",
			},
		],
		[
			{
				img: "https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/honor/9.png",
			},
			{
				img: "https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/honor/10.png",
			},
			{
				img: "https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/honor/11.png",
			},
			{
				img: "https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/honor/12.png",
			},
		],
		[
			{
				img: "https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/honor/13.png",
			},
			{
				img: "https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/honor/14.png",
			},
			{
				img: "https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/honor/15.png",
			},
			{
				img: "https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/honor/16.png",
			},
		],
	])
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
	.school-honor {
		height: 530px;
		width: 100vw;
		background: url("https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/honor-bg.jpg") 0 0 no-repeat;
		background-size: 100%;
		margin-bottom: 50px;
		margin-top: 50px;
		padding-top: 88px;

		.white-title {
			color: #fff;
			font-size: 36px;
			position: relative;
			text-align: center;
			margin-bottom: 40px;
			&::after {
				content: "";
				display: block;
				position: absolute;
				left: 50%;
				bottom: -10px;
				width: 50px;
				height: 3px;
				background: #fff;
				transform: translateX(-50%);
			}
		}

		.white-desc {
			font-size: 24px;
			color: #fff;
			width: 1310px;
			margin: 0 auto;
		}

		.honor-swiper {
			width: 1300px;
			margin: 20px auto 0;

			.img-box {
				display: flex;
				justify-content: center;
				.img-item {
					display: flex;
					.honor-img {
						width: 289px;
					}
				}
			}
		}
	}
</style>
