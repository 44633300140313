console.log(process.env.VUE_APP_API_SERVER)
import axios from "axios"

//使用axios下面的create([config])方法创建axios实例，其中config参数为axios最基本的配置信息。
const API = axios.create({
	baseURL: process.env.VUE_APP_API_SERVER, //请求后端数据的基本地址，自定义
	timeout: 2000, //请求超时设置，单位ms
})

// API.interceptors.request.use(config => {
// 	config.headers = {
// 		'Is-Axios': 1,
// 		'E-Device-Type': 'pc',
// 		'E-App-Id': 'SXNavDTgVUqExtYBTa',
// 		'E-App-Nonce':'lc89hvup',
// 		'E-MHM-ID': 1,
// 		'E-App-Timestamp': (+ new Date()) / 1000
// 		// ...commonHeader,
// 		// ...dokey.secretdate({}),
// 	}
// 	return config
// })
//导出我们建立的axios实例模块，ES6 export用法
export default API
