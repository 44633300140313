<template>
	<div class="teacher-box">
		<el-carousel
			height="472px"
			arrow="always"
			indicator-position="none"
		>
			<el-carousel-item
				v-for="item in dataList"
				:key="item"
			>
				<div class="img-box">
					<div
						class="img-item"
						v-for="(d, index) in item"
						:key="index"
					>
						<img
							class="course-img"
							:src="d.img"
						/>
						<div class="teac-bottom"> <span>{{ d.name }}</span> | {{ d.title }}</div>
					</div>
				</div>
			</el-carousel-item>
		</el-carousel>
	</div>
</template>

<script setup>
	import { reactive } from "vue"
	const dataList = reactive([
		[
			{
				title: "二级建造师",
				name: "安慧",
				img: "https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/teacher/安慧.jpg",
			},
			{
				title: "二级建造师",
				name: "陈洁",
				img: "https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/teacher/陈洁.jpg",
			},
			{
				title: "二级建造师",
				name: "董雨佳",
				img: "https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/teacher/董雨佳.jpg",
			},
		],
		[
			{
				title: "二级建造师",
				name: "黄良辉",
				img: "https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/teacher/黄良辉.jpg",
			},
			{
				title: "二级建造师",
				name: "霍海娥",
				img: "https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/teacher/霍海娥.jpg",
			},
			{
				title: "二级建造师",
				name: "李立军",
				img: "https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/teacher/李立军.jpg",
			},
		],
		[
			{
				title: "二级建造师",
				name: "邵洪芳",
				img: "https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/teacher/邵洪芳.jpg",
			},
			{
				title: "二级建造师",
				name: "宿吉南",
				img: "https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/teacher/宿吉南.jpg",
			},
			{
				title: "二级建造师",
				name: "王飞",
				img: "https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/teacher/王飞.jpg",
			},
		],
		[
			{
				title: "二级建造师",
				name: "王克",
				img: "https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/teacher/王克.jpg",
			},
			{
				title: "二级建造师",
				name: "王欣",
				img: "https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/teacher/王欣.jpg",
			},
			{
				title: "二级建造师",
				name: "王竹梅",
				img: "https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/teacher/王竹梅.jpg",
			},
		],
		[
			{
				title: "二级建造师",
				name: "张云霞",
				img: "https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/teacher/张云霞.jpg",
			},
			{
				title: "二级建造师",
				name: "周超",
				img: "https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/teacher/周超.jpg",
			},
		],
	])
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
	.teacher-box {
    margin-top: 50px;
		.img-box {
			display: flex;
			justify-content: center;

			.img-item {
				position: relative;
        margin: 0 10px;
			}

			.course-img {
				width: 330px;
				height: 440px;
			}

			.teac-bottom {
				position: absolute;
				bottom: 0;
        left: 0;
        width: 100%;
        height: 74px;
        background: url("https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/teac-bottom.png") 0 0 no-repeat;
        background-size: 100%;
        color: #fff;
        line-height: 74px;
        padding-left: 20px;
        font-size: 15px;

        span {
          font-size: 26px;
        }
			}
		}
	}
</style>
