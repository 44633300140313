<template>
  <footer class="footer">
    <div class="footer-container">
      <div class="footer-left">
        <div class="footer-title">联系我们</div>
        <div class="footer-item">
          <div class="item-label">办公地址:</div>
          <div class="item-value">广州市天河区天源路793号B栋</div>
        </div>
        <div class="footer-item">
          <div class="item-label">联系电话:</div>
          <!-- 18922446538 -->
          <div class="item-value">（020）87042608、83372895、37218321</div>
        </div>
        <div class="footer-item">
          <div class="item-label">服务时间:</div>
          <div class="item-value">周一至周六 9:00 - 18:00</div>
        </div>
        <div class="footer-item">
          <div class="item-label"></div>
          <div class="item-value" style="color: transparent;user-select: none;">.</div>
        </div>
      </div>
      <div class="footer-center">
        <div class="center-list">
          <!-- <div class="center-item">
            <img
              class="center-img"
              src="@/assets/images/home/message.png"
            />

            <div class="center-text">企业微信</div>
          </div> -->
          <div class="center-item">
            <img
              class="center-img"
              src="https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/message.png"
            />

            <div class="center-text">公众号</div>
          </div>
          <div class="center-item">
            <img
              class="center-img"
              src="https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/miniapp.png"
            />

            <div class="center-text">小程序</div>
          </div>
          <div class="center-item">
            <img
              class="center-img"
              src="https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/video.png"
            />

            <div class="center-text">视频号</div>
          </div>
        </div>
      </div>
      <div class="footer-right">
        <div class="footer-title">友情链接</div>
        <div class="link-list">
          <div
            class="link-item"
            @click="handleLinkClick(i)"
            v-for="(i, index) in linkList"
            :key="index"
          >
            {{ i.title }}
            {{ index === linkList.length - 1 ? "" : "&nbsp;&nbsp;|" }}
          </div>
        </div>
      </div>
    </div>
    <div class="footer-info">
      <div class="item" @click="toUrl">
        粤ICP备14019768号
      </div>
      <!-- <div class="item" v-if="$util.isDef(siteInfo.copyright)">
        {{ siteInfo.copyright }}
      </div>
      <div class="item" v-if="$util.isDef(siteInfo.portal_code)">
        <img src="@/assets/images/public/gongan.png" alt />
        <a
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51019002004612"
          target="_blank"
          >{{ siteInfo.portal_code }}</a
        >
      </div> -->
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      navList: [],
      linkList: [
        {
          title: '广东省住房和城乡建设厅',
          url: "http://zfcxjst.gd.gov.cn/",
        },
        {
          title: "省建筑施工企业“安管人员”服务中心",
          url: "http://210.76.85.98/#/web",
        },
        {
          title: "广东省建设执业资格注册服务平台",
          url: "http://gdzczx.gdcic.net/",
        },
        {
          title: "广东省专业技术人员继续教育管理系统",
          url:
            "https://gdrst.gdhrss.gov.cn/publicfiles/business/htmlfiles/jxjyglxt/",
        },
        {
          title: "广东省建设工程标准定额站",
          url: "http://www.gdcost.com/",
        },
        {
          title: "广东省建设教育协会",
          url: "http://www.gdpace.com/",
        },
        {
          title: "广东建设信息网",
          url: "http://www.gdcic.net/",
        },
      ],
    };
  },
  computed: {
    siteInfo() {
      return this.$store.getters["config/siteInfo"];
    },
    setMechanismId() {
      return this.$store.getters["config/mechanismId"];
    },
  },
  watch: {
    setMechanismId(n, o) {
      if (n != o) {
        this.handleNavList();
      }
    },
  },
  mounted() {
    // this.handleNavList();
  },
  methods: {
    toUrl() {
      window.open('https://beian.miit.gov.cn/#/Integrated/index')
    },
    handleLinkClick(i) {
      window.open(i.url);
    },
    handleNavList() {
      this.$api.getNaviBottomList((res) => {
        this.navList = res.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-divider--horizontal {
  margin: 0;
  background-color: rgba(0, 0, 0, 0.04);
}
.footer {
  margin-top: 60px;
  color: #d7d7d7;
  background-color: #fff;
  box-sizing: border-box;
  overflow: hidden;

  .footer-container {
    height: 200px;
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.04) inset;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(64, 158, 255, 0.1);

    .footer-left {
      .footer-title {
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        margin-bottom: 23px;
      }

      .footer-item {
        display: flex;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 30px;
        .item-label {
          width: 65px;
        }
      }
    }

    .footer-center {
      margin-left: 55px;
      margin-right: 76px;
      width: 300px;

      .center-list {
        display: flex;
        justify-content: space-around;
        align-items: center;
        .center-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          .center-img {
            width: 60px;
            height: 60px;
            transition: transform 0.3s ease;
            cursor: pointer;
            &:hover {
              transform-origin: 50% 50%;
              transform: scale(3);
            }
          }
          .center-text {
            width: 48px;
            font-size: 12px;
            color: #999999;
            text-align: center;
            margin-top: 8px;
          }
        }
      }
    }

    .footer-right {
      width: 480px;
      height: 158px;
      .footer-title {
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        margin-bottom: 23px;
      }

      .link-list {
        display: flex;
        flex-wrap: wrap;
        .link-item {
          margin-right: 10px;
          margin-bottom: 10px;
          font-size: 13px;
          color: #333333;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .footer-info {
    padding: 15px 0;
    color: #999999;
    .item {
      cursor: pointer;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      a {
        color: #999999;
        text-decoration: none;
      }
      img {
        width: 18px;
        margin-right: 3px;
      }
    }
    .item + .item {
      margin-top: 15px;
    }
  }
}
.page-exam-question .footer {
  margin-top: 0;
}
</style>
