<template>
	<div class="fixed-control">
		<div
			class="customer-item"
			@click="toCustomer"
		>
			<img
				src="https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/customer.png"
			/>
		</div>
		<div class="control-list">
			<div
				class="control-item"
				v-for="i in controlList"
				:key="i.label"
				@click="() => i.clickHandle && i.clickHandle()"
			>
				<el-popover
					v-if="i.hasPopover"
					placement="left"
					:width="200"
					:offset="30"
					trigger="hover"
				>
					<div v-html="i.content"></div>
					<template #reference>
						<div style="text-align: center">
							<img
								style="margin-bottom: 0"
								:src="i.img"
							/>
							<span>{{ i.label }}</span>
						</div>
					</template>
				</el-popover>
				<template v-else>
					<img :src="i.img" />
					<span>{{ i.label }}</span>
				</template>
			</div>
		</div>
	</div>
</template>

<script setup>
	// import { reactive } from "vue"
	import { useRouter } from "vue-router"
	const miniappImg =
		"https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/miniapp.png"
	const messageImg =
		"https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/message.png"

	const toCustomer = () => {
		window.open(
			"https://html.ecqun.com/kf/sdk/openwin.html?corpid=17313614&cstype=rand&mode=0&cskey=FqPXv9wEFSxRiYjYjx&scheme=2&source=45885"
		)
	}

	const router = useRouter()
	const toFeedback = () => {
		router.push({path: '/feedback'})
	}

	const controlList = [
		{
			label: "电话",
			img: "https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/c1.png",
			hasPopover: true,
			content:
				"<div style='text-align: center; color: #0083F5; font-weight: bold;'>020-87042608</div>",
		},
		{
			label: "学习平台",
			img: "https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/study.png",
			clickHandle() {
				window.open("https://edu.yjl.cc/#/?ft=1")
			},
		},
		{
			label: "手机端",
			img: "https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/c2.png",
			hasPopover: true,
			content: `<div style="text-align:center;"><img style='width: 100px;' src="${miniappImg}"></div>`,
		},
		{
			label: "公众号",
			img: "https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/c3.png",
			hasPopover: true,
			content: `<div style="text-align:center;"><img style='width: 100px;' src="${messageImg}"></div>`,
		},
		{
			label: "投诉建议",
			img: "https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/c4.png",
			clickHandle() {
				// toCustomer()
				toFeedback()
			},
		},
		{
			label: "返回顶部",
			img: "https://yjl-front.oss-cn-guangzhou.aliyuncs.com/home/c5.png",
			clickHandle() {
				document
					.querySelector(".common-layout")
					.scrollTo({ top: 0, behavior: "smooth" })
			},
		},
	]
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
	.fixed-control {
		position: fixed;
		top: 50%;
		right: 50px;
		height: 440px;
		transform: translateY(-50%);

		display: flex;
		flex-direction: column;
		align-items: flex-end;

		.customer-item {
			cursor: pointer;
		}

		.control-list {
			.control-item {
				margin-top: 10px;
				width: 62px;
				height: 62px;
				border-radius: 50%;
				font-size: 12px;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				background: #fff;
				box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
				transition: all 0.3s ease;
				cursor: pointer;

				&:hover {
					color: #fff;
					background: #0083f5;
					img {
						filter: brightness(10);
					}
				}

				img {
					transition: all 0.3s ease;
					width: 20px;
					margin-bottom: 4px;
				}

				span {
					display: block;
					transform: scale(0.9);
				}
			}
		}
	}
</style>
